<template>
    <div class="closed-incidents">
        <div class="page-header">

            <h1 class="page-title">Closed Incidents</h1>
            <div class="actions">
                <!-- Search -->
                <a-input-search v-if="false" size="large" placeholder="Search closed incidents" v-model="searchQuery" />
                <!-- / Search -->

                <a-button class="button-margin-left btn-rounded" icon="arrow-left" size="large" type="primary"
                    @click.prevent="goBack">Back</a-button>
            </div>
        </div>

        <div class="date-range-dropdown-wrapper">
            <a-select placeholder="All Incident Types" show-search option-filter-prop="children"
                v-model="dateRangeDropdown" size="large" style="width: 300px">
                <a-select-option value="today">
                    Today
                </a-select-option>
                <a-select-option value="yesterday">
                    Yesterday
                </a-select-option>
                <a-select-option value="last-7-days">
                    Last 7 Days
                </a-select-option>
                <a-select-option value="custom">
                    Custom
                </a-select-option>
            </a-select>
        </div>

        <!-- Shortcuts -->
        <div v-if="false" class="shortcuts">
            <b>Presets: </b>
            <a-button class="shortcut-button" size="small" @click.prevent="todayShortcut">Today</a-button>
            <a-button class="shortcut-button" size="small" @click.prevent="yesterdayShortcut">Yesterday</a-button>
            <a-button class="shortcut-button" size="small" @click.prevent="thisWeekShortcut">This Week</a-button>
            <a-button class="shortcut-button" size="small" @click.prevent="lastWeekShortcut">Last Week</a-button>
        </div>
        <!-- / Shortcuts -->

        <!-- Search Panel -->
        <div v-if="dateRangeDropdown == 'custom'" class="search-panel">
            <a-row :gutter="20" align="middle" justify="center" type="flex">
                <a-col :span="10">
                    <a-form-item style="margin-bottom: 20px" label="Start Date">
                        <a-date-picker style="width: 80%" size="large" v-model="startDate" />
                    </a-form-item>
                </a-col>
                <a-col :span="10">
                    <a-form-item style="margin-bottom: 20px" label="End Date">
                        <a-date-picker style="width: 80%" size="large" v-model="endDate" />
                    </a-form-item>
                </a-col>
                <a-col v-if="false" :span="7">
                    <a-form-item style="margin-bottom: 20px" label="Incident Type">
                        <a-select placeholder="All Incident Types" show-search allow-clear option-filter-prop="children"
                            v-model="incidentType" size="large" style="width: 80%">
                            <!-- <a-select-option :value="null">
                All Incident Types
              </a-select-option> -->
                            <a-select-option :value="incidentType.id" v-for="(incidentType, i) in allIncidentTypes"
                                :key="i">
                                {{ incidentType.displayName }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>
                <a-col :span="4">
                    <a-button @click.prevent="searchIncidents" class="btn-rounded" block icon="search" size="large"
                        type="primary">Search</a-button>
                </a-col>
            </a-row>
        </div>
        <!-- / Search Panel -->

        <!-- List wrapper -->
        <div class="closed-incidents-list-wrapper">
            <!-- Loading -->
            <div class="loader" v-if="isLoadingCombined">
                <a-spin></a-spin>
            </div>
            <!-- / Loading -->

            <!-- No closed incidents -->
            <a-alert v-if="!isLoadingCombined && closedIncidentsToShow.length == 0" type="info"
                message="No incidents to show" />
            <!-- / No closed incidents -->

            <!-- Loaded -->
            <div class="closed-incidents-list" v-if="!isLoadingCombined && closedIncidentsToShow.length">
                <a-row :gutter="20" type="flex">
                    <a-col :span="colSpan" v-for="closedIncident in closedIncidentsToShowOrdered"
                        :key="closedIncident.id">
                        <closed-incident-list-item :incident="closedIncident"></closed-incident-list-item>
                    </a-col>
                </a-row>
            </div>
            <!-- / Loaded -->
        </div>
        <!-- / List wrapper -->
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Dates from '../mixins/Dates';
const _ = require('lodash');
import RequiresNonHiddenPresences from '../mixins/RequiresNonHiddenPresences';
import organisations from '../helpers/organisations';
import self from '../api/self';
import ClosedIncidentListItem from './ClosedIncidents/ClosedIncidentListItem.vue';

export default {
    mixins: [Dates, RequiresNonHiddenPresences],

    data() {
        return {
            searchQuery: '',
            isLoading: false,
            closedIncidents: [],

            dateRangeDropdown: 'today',

            startDate: null,
            endDate: null
        }
    },

    components: {
        ClosedIncidentListItem
    },

    watch: {
        dateRangeDropdown() {
            if (this.dateRangeDropdown == 'today') {
                this.todayShortcut();
            } else if (this.dateRangeDropdown == 'yesterday') {
                this.yesterdayShortcut();
            } else if (this.dateRangeDropdown == 'last-7-days') {
                this.lastSevenDaysShortcut();
            }
        }
    },

    computed: {
        ...mapGetters('missionControl', {
            organisation: 'organisations',
            isLoadingMissionControl: 'isLoading'
        }),

        isLoadingCombined() {
            return this.isLoading || this.isLoadingMissionControl;
        },

        closedIncidentsToShow() {
            if (this.searchQuery.trim().length == 0) {
                return this.closedIncidents;
            }

            return _.filter(this.closedIncidents, incident => {
                return incident.displayName.toLowerCase().includes(this.searchQuery.trim().toLowerCase());
            });
        },

        closedIncidentsToShowOrdered() {
            return _.orderBy(this.closedIncidentsToShow, ['lastActivity'], ['desc']);
        },

        colSpan() {
            return 24;
        }
    },

    created() {
        this.reset();
        this.searchIncidents();
        // this.searchIncidents();
    },

    methods: {
        goBack() {
            this.$router.push('/incidents');
        },

        getClosedIncidentUrl(closedIncident) {
            let organisation = this.getOrganisationById(closedIncident.ownerId);
            let tenantId = organisations.getOrganisationTenantId(organisation);
            return "/incidents/" + tenantId + "/" + closedIncident.id;
        },

        fetch() {
            let vm = this;
            vm.isLoading = true;
            vm.closedIncidents = [];
            self.getArchivedIncidents().then(r => {
                vm.isLoading = false;
                vm.closedIncidents = r.data;
            }).catch(e => {
                console.log(e);
                vm.isLoading = false;
                vm.$message.error('Error loading closed incidents');
            })
        },

        searchIncidents() {
            let vm = this;
            vm.isLoading = true;
            vm.closedIncidents = [];
            self.getArchivedIncidents(
                vm.startDate.format('YYYY-MM-DD'),
                vm.endDate.clone().add(1, 'days').format('YYYY-MM-DD')
            ).then(r => {
                vm.isLoading = false;
                vm.closedIncidents = r.data;
            }).catch(e => {
                console.log(e);
                vm.isLoading = false;
                vm.$message.error('Error loading closed incidents');
            })
        },

        reset() {
            this.startDate = window.moment();
            this.endDate = window.moment();
        },

        todayShortcut() {
            this.startDate = window.moment();
            this.endDate = window.moment();
            this.searchIncidents();
        },

        yesterdayShortcut() {
            this.startDate = window.moment().subtract(1, 'days');
            this.endDate = window.moment().subtract(1, 'days');
            this.searchIncidents();
        },

        thisWeekShortcut() {
            this.startDate = window.moment().startOf('week');
            this.endDate = window.moment().endOf('week');
            this.searchIncidents();
        },

        lastSevenDaysShortcut() {
            this.startDate = window.moment().subtract(6, 'days');
            this.endDate = window.moment();
            this.searchIncidents();
        },

        lastWeekShortcut() {
            this.startDate = window.moment().subtract(1, 'weeks').startOf('week');
            this.endDate = window.moment().subtract(1, 'weeks').endOf('week');
            this.searchIncidents();
        }
    }
}
</script>

<style scoped lang="scss">
.closed-incident-list-item {
    margin-bottom: 15px;
}

.date-range-dropdown-wrapper {
    margin-bottom: 20px;
}

.button-margin-left {
    margin-left: 10px;
}

.shortcuts {
    padding-bottom: 15px;

    .shortcut-button {
        margin-left: 7px;
    }
}

.search-panel {
    background: #fff;
    background: #fff;
    padding: 10px 20px;
    border-radius: 6px;
    font-weight: 500;
    border: 1px solid #fff;
    margin-bottom: 20px;
}

.actions-bar {
    padding-top: 20px;

    display: flex;

    .left {
        flex-grow: 1;
        font-weight: 500;
    }

    .right {
        flex-shrink: 1;
    }

    .action-button {
        margin-left: 7px;
    }
}
</style>